import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Calc from '../views/Calc.vue'
import Pokidex from '../views/Pokidex.vue'
import ToDo from '../views/Todo.vue'
import MoApp from '../views/Moapp.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/calc',
    name: 'Calc',
    component: Calc
  },
  {
    path: '/pokidex',
    name: 'Pokidex',
    component: Pokidex
  },
  {
    path: '/todo',
    name: 'Todo',
    component: ToDo
  },
  {
    path: '/moapp',
    name: 'MoApp',
    component: MoApp
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
