<template>
    <div>
        this is the calc page
        <br/>
        <input type="text" label="value 1" v-model="value1"/><br/>
        <input type="text" label="value 2" v-model="value2"/>
        <br/>
        <button @click="add(value1,value2)">+</button>
        <button @click="sub(value1,value2)">-</button>
        <button @click="mult(value1,value2)">x</button>
        <button @click="dvd(value1,value2)">/</button>
        <hr/>
        {{result}}
    </div>
</template>

<script>
export default{
    name:'Calc',
    data(){
        return{
            value1:0,
            value2:0,
            result:0,
        }
    },
    methods:
    {
        add(v1,v2)
        {
            this.result = Number.parseInt(v1) + Number.parseInt(v2);
        },
        sub(v1,v2)
        {
            this.result = Number.parseInt(v1) - Number.parseInt(v2);
        },
        mult(v1,v2)
        {
            this.result = Number.parseInt(v1) * Number.parseInt(v2);
        },
        dvd(v1,v2)
        {
            this.result = Number.parseInt(v1) / Number.parseInt(v2);
        }
    }
}
</script>