<template>
  <div style="display:flex; flex-direction:column; padding:60px">
    <div style="width:50vw;align-self: center;">
      <v-text-field filled rounded label="write your task" v-model="todoitem">
        <template v-slot:append>
          <v-btn icon small @click="addtask()">
            <v-icon>mdi-send</v-icon>
          </v-btn>
        </template>
      </v-text-field>

      <div class="taskbox" >
        <template>
          <h3>Pending Tasks</h3>
          <div
            @click="changeTaskStatus(t.id)"
            class="taskitem"
            v-for="t in pendingtasks"
            :key="t.id"
          >
            <div style="flex-grow:1;text-align: left;">
              {{ t.id }} - {{ t.task }}
            </div>
            <div>
              {{ t.status }}
            </div>
          </div>
          <h3>Completed Tasks</h3>
          <div
            class="taskitem"
            v-for="t in donetasks"
            :key="t.id"
          >
            <div style="flex-grow:1;text-align: left;">
              {{ t.id }} - {{ t.task }}
            </div>
            <div>
              {{ t.status }}
            </div>
          </div>
        </template>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: "Todo",
  data() {
    return {
      todoitem: "",
      todolist: [],
      taskid:0,
    };
  },
  computed: {
    pendingtasks() {
      return this.todolist.filter((i) => {
        return i.status === "not done";
      });
    },
    donetasks() {
      return this.todolist.filter((i) => {
        return i.status === "done";
      });
    },
  },
  beforeMount()
  {
    this.$gun.get('todo').map().on(this.updatetasks)
  },
  methods: {
    updatetasks(e)
    {
        if(this.todolist.length == 0)
          this.todolist.push(e)
        else if(e.id != this.todolist[this.todolist.length -1].id)
          this.todolist.push(e)
    },
    addtask() {
      let itemToAdd = { id: Math.floor(Date.now()/1000), task: this.todoitem, status: "not done" };
      console.log("item to add is", itemToAdd)
      //this.todolist.push(itemToAdd);
      this.todoitem = "";
      this.$gun.get('todo').set(itemToAdd);
    },
    changeTaskStatus(id) {

      const index = this.pendingtasks.findIndex((i) => {
        return i.id == id;
      })
      const gunid = this.pendingtasks[index]._['#'];
      this.$gun.get(gunid).once((i) => {
        i.status = "done"
        this.$gun.get(gunid).put(i); 
      })
      
    },
  },
};
</script>
<style>
.taskbox {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.taskitem {
  display: flex;
  flex-direction: row;
  width: 300px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  margin: 5px;
  padding: 10px;
  cursor: pointer;
}
</style>
