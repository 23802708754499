<template>
  <div>
    <span v-if="isLoadingAll">Getting Poki Data</span>
    <div style="display:flex; flex-direction:row" v-else>
      <div class="px-2 py-2">
      <v-text-field v-model="search" label="search pokimons" prepend-icon="mdi-magnify"></v-text-field>
      <v-list width="400px">
        <v-virtual-scroll
          :items="filteredPokimons"
          height="calc(100vh - 160px)"
          item-height="60"
          bench="150"
        >
          <template v-slot:default="{ item }">
            <v-list-item @click="getPokimon(item.url)" :key="item.name">
              <v-list-item-content>
                <v-list-item-title>
                  {{ item.name }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider class="my-0 py-0" />
          </template>
        </v-virtual-scroll>
      </v-list>
      </div>
      <div v-if="pokimon">
        <img
          :src="pokimon.sprites.front_default"
          height="200px"
          width="200px"
        />
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">
                  ID
                </th>
                <th class="text-left">
                  Name
                </th>
                <th class="text-left">
                  Height
                </th>
                <th class="text-left">
                  Weight
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{{ pokimon.id }}</td>
                <td>{{ pokimon.name }}</td>
                <td>{{ pokimon.height }}</td>
                <td>{{ pokimon.weight }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Pokidex",
  data() {
    return {
      isLoadingAll: true,
      isLoadingOne: false,
      pokimon: null,
      pokimons: [],
      search:"",
    };
  },
  computed:
  {
    filteredPokimons() {
      return this.pokimons.filter((i) => {
        return !this.search  || i.name.includes(this.search);
      });
    },
  },
  mounted() {
    this.getPokimons();
  },
  methods: {
    getPokimons() {
      this.axios
        .get("https://pokeapi.co/api/v2/pokemon?limit=150&offset=0")
        .then((response) => {
          console.log(response.data);
          this.pokimons = response.data.results;
          this.isLoadingAll = false;
        });
    },
    getPokimon(url) {
      this.axios.get(url).then((response) => {
        console.log(response.data);
        this.pokimon = response.data;
        this.isLoadingOne = false;
      });
    },
  },
};
</script>
<style>
.pokibox {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.pokitem {
  display: flex;
  flex-direction: row;
  text-align: left;
  width: 500px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  margin: 5px;
  padding: 10px;
  cursor: pointer;
  align-items: center;
}
.pokitemcol {
  flex-grow: 1;
  width: 100px;
}
</style>
