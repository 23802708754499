<template>
  <v-row class="px-3 py-3" style="height:calc(100vh - 64px)">
    <v-col cols="3">
      <v-text-field
        hide-details="auto"
        filled
        rounded
        v-model="search"
        label="search users"
        prepend-inner-icon="mdi-magnify"
      ></v-text-field>
      <v-list>
        <v-list-item-group color="primary">
          <v-list-item v-for="user in users" :key="user.name">
            <v-list-item-avatar color="primary" class="white--text" size="40">
              {{ user.name.charAt(0) }}
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>
                {{ user.name }}
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ user.last_msg }}
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-actions style="font-size:0.7rem">
              {{ dateformat(user.timestamp) }}
            </v-list-item-actions>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-col>
    <v-col style="display:flex; flex-direction:column" cols="9">
      <div class="chatbox">
        <div class="msg remote">
          message from asmaa
        </div>
        <div class=" msg local">
          message from shoaibe
        </div>
      </div>
      <v-text-field hide-details="auto" filled rounded v-model="chatmsg" append-icon="mdi-send" />
    </v-col>
  </v-row>
</template>

<script>
import * as dayjs from "dayjs";
export default {
  name: "App",

  data: () => ({
    search: "",
    chatmsg:"",
    users: [
      {
        name: "mosaab ramadan",
        last_msg: "what is this awesome thing",
        timestamp: "2021-08-19T19:19:00",
      },
      {
        name: "Aziz Ben Thabet",
        last_msg: "no, you are the best",
        timestamp: "2021-08-19T19:19:00",
      },
      {
        name: "Ali",
        last_msg: "super awesome",
        timestamp: "2021-08-19T19:19:00",
      },
      {
        name: "Asmaa",
        last_msg: "Cool Stuff",
        timestamp: "2021-08-19T19:19:00",
      },
      {
        name: "Shoaib",
        last_msg: "thank you",
        timestamp: "2021-08-19T19:19:00",
      },
    ],
  }),
  methods: {
    dateformat(date) {
      return dayjs(date).format("D/M/YY h:m a");
    },
  },
};
</script>
<style>
.msg {
  padding: 12px;
  min-width: 200px;
  width: fit-content;
  max-width: 600px;
  color: white;
}
.remote {
  background: #a7c4bc;
  border-radius: 12px 5px 12px 12px;
  align-self: flex-end;
}
.local {
  background: #5e8b7e;
  border-radius: 5px 12px 12px 12px;
}
.chatbox {
  display: flex;
  flex-direction: column;
      height: calc(100vh - 145px);
}
</style>
